<template>
	<div class="container mt-4 mb-4">
		<div class="row">
			<div class="col-12">
				<h1>Política de Cookies</h1>
				<p>
					(Actualizado a 01/06/2022)
				</p>

				<p class="strong">
					1. O que são cookies?
				</p>
				<p>
					Os Cookies são pequenos ficheiros de texto que, quando o utilizador visita determinado website, são
					instalados
					no browser do computador ( ou no dispositivo móvel), para registar a sua actividade, enviando um
					identificador
					anónimo armazenado no computador. Através da utilização de cookies será possível tornar a navegação
					mais
					simples, possibilitando, nomeadamente, o acesso a utilizadores que já tenham efetuado um registo de
					acesso
					sem
					necessidade de registo em cada visita. Através dos Cookies também é possível medir audiências,
					tráfego,
					tempo de
					sessão ou monitorizar o progresso e o número de acessos.
				</p>

				<p>
					A YNEXUS DIGITAL TRIBE, LDA, doravante apenas Ynexus, procurará estabelecer as ferramentas adequadas
					para
					obter
					sempre a autorização do utilizador em momento prévio à instalação dos cookies necessários. Ainda
					assim e, em
					conformidade com a legislação vigente, deverá ser entendido que (i) o Utilizador prestou o seu
					consentimento
					se,
					na configuração do seu browser estiverem desactivadas as restrições preventivas à entrada de cookies
					e (ii)
					que
					o consentimento não é necessário para instalar os cookies que sejam estritamente necessários para
					fornecer
					um
					serviço que tenha sido expressamente requerido pelo Utilizador (através de um registo prévio).
				</p>
				<p class="strong">
					2. Cookies que utilizamos:
				</p>
				<p>
					Na seguinte tabela encontra-se uma lista dos cookies que a Ynexus utiliza, bem como, as respectivas
					finalidade,
					tipologia e validade:
				</p>

				<table class="table table-bordered">
					<thead>
					<tr>
						<th>Cookie Id</th>
						<th>Finalidade</th>
						<th>Vendor</th>
						<th>Tipo Cookie</th>
						<th>Validade</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td>_ga</td>
						<td>Usado para distinguir usuários.</td>
						<td>Google</td>
						<td>Analítica</td>
						<td>2 anos</td>
					</tr>
					<tr>
						<td>_gid</td>
						<td>Usado para distinguir usuários.</td>
						<td>Google</td>
						<td>Analítica</td>
						<td>24 horas</td>
					</tr>
					<tr>
						<td>_fbp</td>
						<td>
							Usado para medir o desempenho da publicidade no Facebook e exibir anúncios personalizados
							na plataforma
							do Facebook.
						</td>
						<td>Facebook</td>
						<td>Publicidade</td>
						<td>3 meses</td>
					</tr>
					<tr>
						<td>_cfduid</td>
						<td>
							Usado para auxiliar o Cloudflare a detectar visitantes mal-intencionados, minimizando o
							bloqueio de
							utilizadores
							legítimos. É necessário para suportar os recursos de segurança do Cloudflare.
							Este cookie recolhe e anonimiza os endereços IP do utilizador, através de um hash
							unidirecional, para
							que os
							seus dados não possam ser identificados.
						</td>
						<td>Cloudflare</td>
						<td>Essencial</td>
						<td>30 dias</td>
					</tr>
					</tbody>
				</table>

				<p class="strong">
					3. Tipos de cookies
				</p>
				<p>
					Como consta da tabela acima, os cookies que utilizamos têm diferentes finalidades, que são as
					seguintes:
				</p>
				<ol type="A">
					<li>
						Cookies essenciais: essenciais para aceder a áreas específicas do nosso site (permitem, por
						exemplo,
						a
						navegação no site e a utilização das suas aplicações). Sem estes cookies, os serviços que os
						exijam não
						podem
						ser prestados.
					</li>
					<li>
						Cookies analíticos: analisam a forma como os utilizadores usam o site e monitoram a
						performance
						deste(permitem prover uma experiência de excelência, personalizando a nossa oferta,
						identificando e
						corrigindo
						quaisquer problemas que surjam. Por exemplo, usamos cookies de desempenho para saber quais as
						páginas
						mais
						populares ou qual o método de ligação entre páginas mais eficaz. Estes cookies só são usados
						para
						criação e
						análise estatística, sem recolher informação pessoal.
					</li>
					<li>
						Cookies de funcionalidade: memorizam as preferências do utilizador, de forma que não seja
						necessário
						voltar a
						configurar o site cada vez que o visita.
					</li>
					<li>
						Cookies de terceiros: medem o sucesso de aplicações e a eficácia da publicidade de terceiros
						no nosso
						site.
					</li>
					<li>
						Cookies de publicidade: escolhem a publicidade em função dos interesses de cada utilizador e
						das
						visitas que
						realizou, medindo a eficácia da publicidade e limitando a repetição da visualização do anúncio.
					</li>
				</ol>
				<p class="strong">
					4. Gestão de Cookies
				</p>
				<p>
					O utilizador poderá, em qualquer momento, configurar o seu browser de modo a ser notificado sobre a
					recepção
					de
					cookies e prevenindo a instalação dos mesmos no seu sistema.
				</p>
				<p>
					Notamos, porém, que ao desactivar os cookies poderá modificar o desempenho do website, podendo
					resultar na
					impossibilidade de ter acesso a algumas das suas áreas ou de receber informação personalizada.
				</p>

				<p>
					Seguidamente, deixamos instruções de como pode alterar as suas definições de cookies que devem ser
					lidas em
					conjunto com as orientações e definições do seu browser:
				</p>
				<ol type="A">
					<li>
						<strong>Microsoft Internet Explorer:</strong> dentro da opção do menu Ferramentas, selecione
						Opções da
						Internet e aceda a Privacidade;
					</li>
					<li>
						<strong>Firefox:</strong> no caso do Mac, dentro da opção do menu Preferências, selecione
						Privacidade e
						aceda à secção Mostrar
						Cookies e, no caso do Windows dentro da opção do menu Ferramentas, selecione Opções e aceda a
						Privacidade e
						depois selecione Usar configurações personalizadas;
					</li>
					<li><strong>Safari:</strong> dentro da opção do menu Preferências, selecione Privacidade;</li>
					<li>
						<strong>Google Chrome:</strong> dentro da opção do menu Ferramentas, selecione Opções –
						Preferências no
						Mac - e aceda a Avançadas
						e depois, na opção Definições, Conteúdos na secção Privacidade e, finalmente, selecione Cookies
						na caixa
						de
						diálogo Conteúdo.
					</li>
				</ol>
				<p>
					Para mais informações poderá consultar, quando disponíveis, os seguintes sites, onde também poderá
					descativar
					os seus cookies: <a href="http://www.aboutads.info/choices/#completed" target="_blank">http://www.aboutads.info/choices/#completed</a>
					ou <a href="http://www.youronlinechoices.eu" target="_blank">http://www.youronlinechoices.eu/</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name       : 'CookiesPage',
	components : {},
	data() {
		return {
			isLoaded    : false,
			clientName  : String,
			clientEmail : String,
			cookiesLink : String,
			termsLink   : String
		};
	},
	mounted() {
		let res = this.$store.state.resData.data;
		if (res.setup.privacy_name !== null && res.setup.privacy_email !== null && res.setup.privacy_address !== null) {
			this.isLoaded    = true;
			this.clientName  = res.setup.privacy_name;
			this.clientEmail = res.setup.privacy_email;
			this.cookiesLink = `${window.location.href}/politica-cookies/${this.$route.params.seller_id}`;
			this.termsLink   = `${window.location.href}/termos-condicoes/${this.$route.params.seller_id}`;
		} else {
			window.location.href = res.redirect_url;
		}
	},
};
</script>

<style lang="scss">
h1 {
	font-size: 1.6rem;
	font-weight: 700;
}

p, li, td, th {
	font-size: 0.85rem;

	&.strong {
		font-weight: 700;
		margin-top: 2rem;
	}
}

a {
	color: #343942;
	text-decoration: underline;
}

ol {
	li {
		span {
			text-decoration: underline;
		}
	}
}
</style>
